<template>
  <base-view
      @click:add="criar"
      :permiteCadastrar="permiteCadastrar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <CreateEditCompradorDialog :item="item" v-model="editDialogOpened"
                                  @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <CompradorDataTable ref="dataTable"
                             @click:edit="editar"
                             @click:remove="remover"
                         :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import CompradorDataTable from "../../components/shared/datatables/CompradorDataTable";
import BaseView from "../BaseView";
import {remove} from "../../api/comprador";
import CreateEditCompradorDialog from "../../components/shared/dialogs/CreateEditCompradorDialog";
import {ApiError} from "../../api/core";
import {mapGetters} from "vuex";
//PKs
import {findAll as findAllAtividadeEconomica} from "../../api/atividadeEconomica";

export default {
  name: "index",
  components: {CreateEditCompradorDialog, BaseView, CompradorDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      permiteCadastrar: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'ID',
          column: 'id',
          type: 'number',
          prefix: '#'
        },
        {
          name: 'Nome',
          column: 'nome',
          type: 'text'
        },        
        {
          name: 'Descricao',
          column: 'descricao',
          type: 'text'
        },
        {
          name: 'CNPJ',
          column: 'cnpj',
          type: 'text'
        },
        {
          name: 'Atividade Econômica',
          column: 'idAtividadeEconomica',
          type: 'autocomplete',
          optionsFactory: (search) => findAllAtividadeEconomica(1,{
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })
        },        
      ]
    }
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated','nivelUsuarioLogado']),
  },  
  methods: {
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {
        id: null,
        idAtividadeEconomica: null,
        descricaoAtividadeEconomica: "",
        nomeFantasia: "",
        razaoSocial: "",
        descricao: "",
        cnpj: "",
        senha: "",
        endereco: "",
        bairro: "",
        complemento: "",
        cidade: "",
        estado: "",
        cep: "",
        telefone: "",
        email: "",
        siteRede: "",
        contatos: [],
      };
      this.editDialogOpened = true;
    },
    editar(objeto) {
      this.item = JSON.parse(JSON.stringify(objeto));
      this.editDialogOpened = true;
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Comprador removido com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {        
          this.$toast.error(`Não foi possível remover o Comprador.`, {
            timeout: 6000
          })
        }  
      }
    },
	  async getPermissoes(){
      this.permiteCadastrar = await this.getPermissaoPorItemPerfil("Comprador.cadastro",this.nivelUsuarioLogado);
    },
  },
  mounted() {
    this.getPermissoes();
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>